@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
@keyframes pulse {
  0%,
  100% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.5;
  }
}

@-webkit-keyframes pulse {
  0%,
  100% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.5;
  }
}

/**
* https://github.com/facebook/create-react-app/issues/11773#issuecomment-1047510705
**/
iframe {
  pointer-events: none;
}

.calendly-inline-widget iframe {
  pointer-events: auto;
}
